import React, { useEffect } from 'react'
import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getAdjustedTreatmentFee,
  getDefaultPrimaryPayor,
} from 'Data/payment-plan.js'

import View from './view.js'

export default function Logic(props) {
  let payment_plan = useDataValue({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    path: 'primary_payor',
    viewPath: props.viewPath,
  })
  let treatment_fee = getAdjustedTreatmentFee(payment_plan)

  useEffect(() => {
    if (typeof treatment_fee !== 'number') return

    // reset the selection whenever there is a change in the treatment fee
    change(getDefaultPrimaryPayor(payment_plan.primary_payor.id, treatment_fee))
  }, [treatment_fee]) // eslint-disable-line react-hooks/exhaustive-deps
  // ignore payment_plan.primary_payor.id

  return <View {...props} />
}
