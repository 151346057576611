import { useDataChange, useDataValue } from 'Simple/Data.js'
import {
  getAdjustedTreatmentFee,
  getDefaultPrimaryPayor,
} from 'Data/payment-plan.js'

export function useOnClick(props) {
  let payment_plan = useDataValue({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onClick() {
    // TODO: logic only added for primary payor (secondary will be supported later)
    change(next => {
      let remaining_fee = getAdjustedTreatmentFee(payment_plan)
      next.primary_payor = getDefaultPrimaryPayor(
        crypto.randomUUID(),
        remaining_fee
      )
    })
  }
}
