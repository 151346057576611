import { useDataChange } from 'Simple/Data.js'
import { getNumberOfInstallmentsPerMonth, LOCK } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      if (value === next.primary_payor.installment_amount) return

      next.primary_payor.installment_amount = value

      if (next.primary_payor.payment_plan_length === 0) {
        next.primary_payor.payment_plan_length = 1
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        let monthly_installment_amount =
          value * number_of_installments_per_month
        next.primary_payor.downpayment =
          next.primary_payor.share.amount - monthly_installment_amount
        next.primary_payor.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (value === 0) {
        // pay in full
        next.primary_payor.downpayment = next.primary_payor.share.amount
        next.primary_payor.payment_plan_length = 0
        next.primary_payor.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (next.primary_payor.lock === LOCK.PAYMENT_PLAN_LENGTH) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        let total_installments_amount =
          value *
          next.primary_payor.payment_plan_length *
          number_of_installments_per_month
        next.primary_payor.downpayment =
          next.primary_payor.share.amount - total_installments_amount
      } else if (next.primary_payor.lock === LOCK.DOWNPAYMENT) {
        let total_installments_amount =
          next.primary_payor.share.amount - next.primary_payor.downpayment
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        let monthly_installment_amount =
          value * number_of_installments_per_month
        next.primary_payor.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
        let maximum_installment_amount =
          total_installments_amount / number_of_installments_per_month
        next.primary_payor.installment_amount = Math.min(
          parseFloat(maximum_installment_amount.toFixed(2)),
          value
        )
      }
    })
  }
}
