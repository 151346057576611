import React from 'react'
import { useDataValue } from 'Simple/Data.js'
import View from './view.js'

export default function Logic(props) {
  let secondary_payor = useDataValue({
    context: 'payment_plan',
    path: 'secondary_payor',
    viewPath: props.viewPath,
  })
  return <View {...props} disabled={!!secondary_payor} />
}
