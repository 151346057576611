import { useDataChange } from 'Simple/Data.js'
import { dateShortOutTempFixForMaskedInput } from 'Data/format.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      let first_due_date = dateShortOutTempFixForMaskedInput(value)
      next.primary_payor.first_due_date = first_due_date

      if (
        ['Weekly', 'EverySecondWeek'].includes(
          next.primary_payor.installment_interval
        )
      ) {
        let day_of_the_week = new Date(first_due_date).getDay()
        // expecting to be 1-7, while JS returns 0-6 (starting with Sunday)
        next.primary_payor.first_installment_date =
          day_of_the_week === 0 ? 7 : day_of_the_week
      } else {
        next.primary_payor.first_installment_date = new Date(
          first_due_date
        ).getDate()
      }
    })
  }
}
