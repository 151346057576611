import { useMemo } from 'react'
import { getDefaultPrimaryPayor } from 'Data/payment-plan.js'

// TODO: get it from treatment plan when real data is used
let MOCK_TREATMENT_FEE = 10000.0

export default function useDataTransform(props, data) {
  return useMemo(() => {
    return {
      treatment_fee: MOCK_TREATMENT_FEE,
      discounts: [],
      charges: [],
      insurances: [],
      primary_payor: getDefaultPrimaryPayor(
        crypto.randomUUID(),
        MOCK_TREATMENT_FEE
      ),
      secondary_payor: null,
    }
  }, [])
}
