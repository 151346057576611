import { useDataChange } from 'Simple/Data.js'
import { getNumberOfInstallmentsPerMonth, LOCK } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      if (value === next.primary_payor.payment_plan_length) return

      next.primary_payor.payment_plan_length = value

      if (value === 0) {
        next.primary_payor.installment_amount = 0
        next.primary_payor.downpayment = next.primary_payor.share.amount
      } else if (next.primary_payor.lock === LOCK.INSTALLMENT_AMOUNT) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        let total_installments_amount = Math.min(
          next.primary_payor.installment_amount *
            value *
            number_of_installments_per_month,
          next.primary_payor.share.amount
        )
        next.primary_payor.downpayment =
          next.primary_payor.share.amount - total_installments_amount
        let monthly_installment_amount =
          next.primary_payor.installment_amount *
          number_of_installments_per_month
        // reset the installment length to not allow to have negative installment amounts
        next.primary_payor.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
      } else if (next.primary_payor.lock === LOCK.DOWNPAYMENT) {
        let total_installments_amount =
          next.primary_payor.share.amount - next.primary_payor.downpayment
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        let total_number_of_installments =
          value * number_of_installments_per_month
        let installment_amount =
          total_installments_amount / total_number_of_installments
        next.primary_payor.installment_amount = parseFloat(
          installment_amount.toFixed(2)
        )
      }
    })
  }
}

export function formatPaymentPlanLength(value) {
  return `${value} months`
}

export function isDisabled(lock, installment_amount) {
  return lock === LOCK.PAYMENT_PLAN_LENGTH || installment_amount === 0
}
