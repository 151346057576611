import addDays from 'date-fns/addDays/index'
import addMonths from 'date-fns/addMonths/index.js'
import isBefore from 'date-fns/isBefore/index.js'
import isPast from 'date-fns/isPast/index.js'
import setDate from 'date-fns/setDate/index.js'
import setDay from 'date-fns/setDay/index.js'
import formatISO from 'date-fns/formatISO/index.js'
import { useDataChange } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.primary_payor.first_installment_date = value
      if (
        ['Weekly', 'EverySecondWeek'].includes(
          next.primary_payor.installment_interval
        )
      ) {
        // JS days of the week are 0-6, while it's expected to be stored as 1-7
        let day_of_the_week = value === 7 ? 0 : value
        let first_due_date = new Date(next.primary_payor.first_due_date)
        let previous_or_same_sunday = setDay(first_due_date, 0)
        let next_or_same_day = setDay(
          previous_or_same_sunday,
          day_of_the_week,
          {
            weekStartsOn: 1,
          }
        )
        if (isBefore(next_or_same_day, first_due_date)) {
          next_or_same_day = addDays(next_or_same_day, 7)
        }
        next.primary_payor.first_due_date = formatISO(next_or_same_day, {
          representation: 'date',
        })
      } else {
        let first_due_date = new Date(next.primary_payor.first_due_date)
        let updated_due_date = setDate(first_due_date, value)
        if (isPast(updated_due_date)) {
          next.primary_payor.first_due_date = formatISO(
            addMonths(updated_due_date, 1),
            { representation: 'date' }
          )
        } else {
          next.primary_payor.first_due_date = formatISO(updated_due_date, {
            representation: 'date',
          })
        }
      }
    })
  }
}
