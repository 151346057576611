import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  let treatment_fee = useDataValue({
    context: 'payment_plan',
    path: 'treatment_fee',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.primary_payor.share.percentage = value
      next.primary_payor.share.amount = parseFloat(
        ((treatment_fee * value) / 100).toFixed(2)
      )
      next.primary_payor.share.type = 'percentage'
    })
  }
}
