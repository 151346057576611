import { useDataChange } from 'Simple/Data.js'
import { getNumberOfInstallmentsPerMonth, LOCK } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      if (value === next.primary_payor.downpayment) return

      next.primary_payor.downpayment = value

      if (value === next.primary_payor.share.amount) {
        // no installment amount or installment length as paid in full
        next.primary_payor.installment_amount = 0
        next.primary_payor.payment_plan_length = 0
        next.primary_payor.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (next.primary_payor.payment_plan_length === 0) {
        next.primary_payor.payment_plan_length = 1
        let total_installments_amount = next.primary_payor.share.amount - value
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        next.primary_payor.installment_amount =
          total_installments_amount / number_of_installments_per_month
        next.primary_payor.lock = LOCK.PAYMENT_PLAN_LENGTH
      } else if (next.primary_payor.lock === LOCK.PAYMENT_PLAN_LENGTH) {
        let total_installments_amount = next.primary_payor.share.amount - value
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        let total_number_of_installments =
          next.primary_payor.payment_plan_length *
          number_of_installments_per_month
        let installment_amount =
          total_installments_amount / total_number_of_installments

        next.primary_payor.installment_amount = parseFloat(
          installment_amount.toFixed(2)
        )
      } else if (next.primary_payor.lock === LOCK.INSTALLMENT_AMOUNT) {
        let number_of_installments_per_month = getNumberOfInstallmentsPerMonth(
          next.primary_payor.installment_interval
        )
        let monthly_installment_amount =
          next.primary_payor.installment_amount *
          number_of_installments_per_month
        // prevent it from going over 100% of the payor's share
        let downpayment = Math.min(
          value,
          next.primary_payor.share.amount - monthly_installment_amount
        )
        let total_installments_amount =
          next.primary_payor.share.amount - downpayment

        next.primary_payor.payment_plan_length = Math.ceil(
          total_installments_amount / monthly_installment_amount
        )
        next.primary_payor.downpayment = downpayment
      }
    })
  }
}
