import formatISO from 'date-fns/formatISO/index.js'
import addMonths from 'date-fns/addMonths/index.js'
import startOfMonth from 'date-fns/startOfMonth/index.js'

export let LOCK = /** @type {const} */ {
  INSTALLMENT_AMOUNT: 'INSTALLMENT_AMOUNT',
  DOWNPAYMENT: 'DOWNPAYMENT',
  PAYMENT_PLAN_LENGTH: 'PAYMENT_PLAN_LENGTH',
}

/**
 * Returns the number of installments in a month depending on the chosen interval
 * @param {*} installment_interval
 * @returns {number}
 */
export function getNumberOfInstallmentsPerMonth(installment_interval) {
  switch (installment_interval) {
    case 'Weekly':
      return 4
    case 'EverySecondWeek':
    case 'TwicePerMonth':
      return 2
    case 'Monthly':
    default:
      return 1
  }
}

/**
 * Returns the total treatment fee after applying the discounts and charges
 *
 * @param {*} payment_plan
 * @returns {number}
 */
export function getAdjustedTreatmentFee(payment_plan) {
  // discounts could be either 'fixed amount' or 'percentage', but the values should always be in sync
  let result = payment_plan.discounts.reduce(
    (acc, discount) => acc - discount.amount,
    payment_plan.treatment_fee
  )
  return payment_plan.charges.reduce(
    (acc, charge) => acc + charge.amount,
    result
  )
}

/**
 * Returns the initial configuration for the payor (i.e. pay in full 100% of the remaining fee)
 *
 * @param {string} id
 * @param {number} base_fee
 */
export function getDefaultPrimaryPayor(id, base_fee) {
  let first_day_of_next_month = startOfMonth(addMonths(new Date(), 1))
  return {
    id,
    share: {
      type: 'percentage',
      amount: base_fee,
      percentage: 100,
    },
    // pay-in-full by default
    downpayment: base_fee,
    installment_amount: 0,
    payment_plan_length: 0,
    installment_interval: 'Monthly',
    first_due_date: formatISO(first_day_of_next_month, {
      representation: 'date',
    }),
    first_installment_date: 1,
    second_installment_date: 1,
  }
}
