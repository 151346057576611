import { useDataChange, useDataValue } from 'Simple/Data.js'
import { LOCK } from 'Data/payment-plan.js'

export function useOnLock(props) {
  let lock = useDataValue({
    context: 'payment_plan',
    path: 'primary_payor.lock',
    viewPath: props.viewPath,
  })
  let setLock = useDataChange({
    context: 'payment_plan',
    path: 'primary_payor.lock',
    viewPath: props.viewPath,
  })

  return function onLock() {
    setLock(
      lock === LOCK.INSTALLMENT_AMOUNT
        ? LOCK.DOWNPAYMENT
        : LOCK.INSTALLMENT_AMOUNT
    )
  }
}
