import { useDataChange, useDataValue } from 'Simple/Data.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })

  return function onChange(value) {
    change(next => {
      next.primary_payor.share.amount = value
      next.primary_payor.share.type = 'amount'
      if (next.treatment_fee > 0) {
        next.primary_payor.share.percentage = Math.round(
          (value / next.treatment_fee) * 100
        )
      }
    })
  }
}
