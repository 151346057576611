import formatISO from 'date-fns/formatISO/index.js'
import addMonths from 'date-fns/addMonths/index.js'
import startOfMonth from 'date-fns/startOfMonth/index.js'
import { useDataChange } from 'Simple/Data.js'
import { getNumberOfInstallmentsPerMonth } from 'Data/payment-plan.js'

export function useOnChange(props) {
  let change = useDataChange({
    context: 'payment_plan',
    viewPath: props.viewPath,
  })
  return function onChange(value) {
    change(next => {
      next.primary_payor.installment_interval = value
      // reset these values when interval changed because
      //  it is used for both storing the day of the week and day of the month
      next.primary_payor.first_installment_date = 1
      next.primary_payor.second_installment_date = 1
      let first_day_of_next_month = startOfMonth(addMonths(new Date(), 1))
      next.primary_payor.first_due_date = formatISO(first_day_of_next_month, {
        representation: 'date',
      })

      let total_installments_amount =
        next.primary_payor.share.amount - next.primary_payor.downpayment
      let total_number_of_installments =
        next.primary_payor.payment_plan_length *
        getNumberOfInstallmentsPerMonth(value)
      let installment_amount =
        total_installments_amount / total_number_of_installments
      // reset the installment amount to reflect the new interval
      next.primary_payor.installment_amount = parseFloat(
        installment_amount.toFixed(2)
      )
    })
  }
}
